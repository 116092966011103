import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGroupsList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'title', sortable: true },
        { key: 'users_count', label: '# of Users'},
        { key: 'fieldOps', label: 'Field Ops?', sortable: true },
        { key: 'status', sortable: true },
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('title')
    const isSortDirDesc = ref(true)

    const refetch = () => {
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-group/list', {
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { roles, meta } = response.data

            meta.value = meta
            
            callback(roles)
            totalRecords.value = meta.value.total
            from.value = meta.value.from
            to.value = meta.value.to
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching groups list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    const resolveStatusVariant = status => {
        if (status) return 'success'
        if (!status) return 'secondary'
    }

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
    
        // resolveUserRoleVariant,
        // resolveUserRoleIcon,
        resolveStatusVariant,
        refetch,
    
        // Extra Filters
        // roleFilter,
        // planFilter,
        // statusFilter,
    }
}