<template>
    <div>
        <!-- Form -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <validation-observer ref="dataForm" #default="{ invalid }">
                            <b-form ref="form" @submit.stop.prevent="">
                                <b-row>
                                    <b-col cols="12" md="4">
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group labe-for="title" label="Name">
                                                    <validation-provider #default="{ errors }" name="Name" rules="">
                                                        <b-form-input
                                                            id="title"
                                                            name="title"
                                                            size="sm"
                                                            placeholder="Group name"
                                                            v-model="role.title"
                                                            :state="errors.length > 0 ? false : null"
                                                            auto-focus
                                                        />
                                                        <small class="text-danger" v-if="errors">{{ errors[0] }}</small>
                                                        <small
                                                            class="text-danger"
                                                            v-if="serverErrors && serverErrors.title"
                                                        >
                                                            {{ serverErrors.title[0] }}
                                                        </small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-checkbox
                                                    checked="false"
                                                    class="custom-control-success"
                                                    name="fieldOps"
                                                    switch
                                                    v-model="role.fieldOps"
                                                >
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckCircleIcon" />
                                                    </span>
                                                    <span>On Field Operations?</span>                                 
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-form-checkbox
                                                    checked="false"
                                                    class="custom-control-success"
                                                    name="status"
                                                    switch
                                                    v-model="role.status"
                                                >
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckCircleIcon" />
                                                    </span>
                                                    <span>Is Active?</span>                                 
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-button
                                                    size="sm"
                                                    variant="primary"
                                                    @click="submit"
                                                >
                                                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                                    <span v-if="!saving">Save</span>
                                                    <span v-if="saving">Saving...</span>
                                                </b-button>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-button
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    @click="resetForm"
                                                >
                                                    Reset
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="12" md="8">
                                        <h6>Permissions</h6>
                                        <div
                                            style="border: 1px solid #eee;height:15vh;overflow-y:scroll;overflow-x: hidden;padding: 8px 8px;"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="6"
                                                    md="3"
                                                    v-for="(ability, index) in abilities"
                                                    :key="index"
                                                >
                                                    <b-form-checkbox
                                                        checked="false"
                                                        class="custom-control-success"
                                                        :value="ability.id"
                                                        :label="ability.title"
                                                        v-model="role.permissions"
                                                    >
                                                        <small>{{ ability.title }}</small>
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>
            </b-card-body>
            <!-- <b-card-body>{{ role }}</b-card-body> -->
        </b-card>
        <!-- ./Form -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(data.item.status)}`"
                        class="text-capitalize"
                    >
                        {{ data.item.status ? 'Active' : 'Inactive' }}
                    </b-badge>
                </template>

                <template #cell(fieldOps)="data">
                    {{ data.item.fieldOps ? 'Yes' : 'No' }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
    BCard, BCardHeader, BCardBody,
    BRow, BCol,
    BForm, BFormGroup, BFormInput, BFormCheckbox, BDropdown, BDropdownItem,
    BButton, BSpinner,
    BTable, BPagination, BBadge, BAvatar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, computed } from '@vue/composition-api'
import groupsStoreModule from '@/views/cromis/role/groupsStoreModule'
import useGroupsList from '@/views/cromis/role/useGroupsList'
export default {
    props: {},
    components: {
        BCard, BCardHeader, BCardBody,
        BRow, BCol,
        BForm, BFormGroup, BFormInput, BFormCheckbox, BDropdown, BDropdownItem, vSelect,
        BButton, BSpinner,
        BTable, BPagination, BBadge, BAvatar,
        ValidationProvider, ValidationObserver,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const abilities = ref([])
        const role = ref({
            id: null,
            title: null,
            status: true,
            fieldOps: false,
            permissions: [],
        })

        const CROMIS_GROUP_STORE_MODULE_NAME = 'cromis-group'

        // Register module
        if (!store.hasModule(CROMIS_GROUP_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROUP_STORE_MODULE_NAME, groupsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_GROUP_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROUP_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-group/abilities')
                        .then(response => {
                            abilities.value = response.data.abilities
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })
        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveStatusVariant,
        } = useGroupsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            role.value = item
        }

        const resetForm = () => {
            role.value = {
                id: null,
                title: null,
                status: true,
                fieldOps: false,
                permissions: [],
            }
        }

        const isFormValid = async () => {
            let isValid = false 
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()
            serverErrors.value = null

            // Handle form submit
            if (role.value.id === null || role.value.id === 0)
                handleCreate()
            else
                handleUpdate(role.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-group/create', role.value)
                .then(response => {
                    refetch()
                    saving.value = false
                    resetForm()

                    context.root.$swal({
                        icon: 'success',
                        text: `User group ${response.data.title} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    resetForm()
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if(!valid){
                saving.value = false
                return
            }

            await store.dispatch('cromis-group/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to ${response.data.title} has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                    resetForm()
                })
                .catch(error => {
                    saving.value = false
                    resetForm()
                    console.log(error)
                    if(error.response.status === 422){
                        serverErrors.value = error.response.data.errors
                    }
                    else{
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-group/remove', id)
                        .then(response => {
                            refetch()
                        })
                        .catch(error => {
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: false,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        })
        }
        
        return {
            // Collections
            abilities,

            // Form
            dataForm,
            saving,
            serverErrors,
            role,
            isFormValid,
            submit,
            handleCreate,
            invokeUpdateForm,
            handleUpdate,
            remove,
            resetForm,

            // Validations
            required,

            // Table
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>